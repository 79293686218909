export const SidebarData = [
    {
        title: "Home",
        cName: 'nav-text'
    },
    {
        title: "New",
        cName: 'nav-text'
    },
    {
        title: "Popular",
        cName: 'nav-text'
    },
    {
        title: "Trending",
        cName: 'nav-text'
    },
    {
        title: "Categories",
        cName: 'nav-text'
    },
]

export default SidebarData;